<template>
    <v-row>
        <v-col cols="12" md="6">
            <DashCard title="Información General" title-icon="assignment">
                <v-form id="form" ref="form">
                    <v-row>
                        <v-col cols="12">
                            <v-label>Título de la evaluación</v-label>
                            <v-text-field solo hide-details outlined dense flat placeholder="Titulo" v-model="item.nombre" :rules="[rules.required]" />
                        </v-col>
                        <v-col cols="12">
                            <v-label>Descripción breve de la evaluación</v-label>
                            <v-textarea solo hide-details outlined dense flat placeholder="Descripción breve que será mostrada en el listado de evaluaciones. (Máx 150 carácteres)" v-model="item.descripcion" counter="150" />
                        </v-col>
                        <v-col cols="12">
                            <v-checkbox class="ma-0 pt-0 pb-4" v-model="item.is_grupal" label="Es una prueba grupal" hide-details />
                        </v-col>
                    </v-row>
                </v-form>
            </DashCard>
        </v-col>
        <v-col cols="12" md="6">
            <DashCard title="Material de apoyo" title-icon="edit">
                <v-row>
                    <v-col cols="12">
                        <v-card outlined>
                            <v-card-text>
                                <v-label>Una evaluación requiere del material de apoyo que será mostrado antes de comenzar con el cuestionario.</v-label>
                                <v-divider class="my-4" />
                                <v-row class="ma-0" no-gutters>
                                    <v-col class="flex-grow-1 flex-shrink-0">
                                        <v-alert v-if="!item.presentacion" dense type="error">No hay material seleccionado</v-alert>
                                        <v-card outlined flat v-else>
                                            <v-card-text class="pa-2">
                                                <span class="subtitle-1 font-weight-bold text--primary d-block">{{ item.presentacion.nombre }}</span>
                                                <span class="subtitle-1 d-block">{{ item.presentacion.creacion | moment("LL HH:mm") }}</span>
                                            </v-card-text>
                                         </v-card>
                                    </v-col>
                                    <v-col class="flex-grow-0 flex-shrink-1">
                                        <v-flex class="ma-n1 px-2">
                                            <v-btn depressed color="primary" class="subtitle-2 ma-1" @click="$refs.pdf_selector.show(item.presentacion)">
                                                <v-icon left>attach_file</v-icon>Seleccionar
                                            </v-btn>
                                            <!-- Descomentar cuando este disponible el visualizador de pdf -->
                                            <!-- <v-btn depressed color="primary" class="subtitle-2 ma-1" @click="$refs.pdf_preview.show(item.presentacion.archivo)" :disabled="!item.presentacion">
                                                <v-icon left>visibility</v-icon>Vista previa
                                            </v-btn> -->
                                        </v-flex>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </DashCard>
        </v-col>
        <v-col cols="12">
            <DashCard title="Instrucciones" title-icon="announcement">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-label>Instrucciones adicionales del Material de Apoyo</v-label>
                        <v-textarea solo hide-details outlined dense flat v-model="item.instruccion_material" counter="250"  placeholder="Texto instructivo e introductorio que se mostrará junto al material de apoyo"/>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-label>Instrucciones de la evaluación</v-label>
                        <v-textarea solo hide-details outlined dense flat v-model="item.instruccion_exam" counter="250" placeholder="Texto instructivo que se mostrará arriba de cada pregunta" />
                    </v-col>
                </v-row>
            </DashCard>
        </v-col>
        <v-col cols="12">
            <DashCard :actions="true" actions-align="right" title="Preguntas" title-icon="category" body-class="pa-0">
                <PreguntasEditor ref="editor" v-model="item.preguntas" />
                <template slot="actions">
                    <v-btn depressed color="primary" class="subtitle-2" @click="$refs.editor.trigger_new()">
                        <v-icon left>add</v-icon>Añadir
                    </v-btn>
                    <v-btn depressed color="primary" class="subtitle-2">
                        <v-icon left>close</v-icon>Eliminar todo
                    </v-btn>
                </template>
            </DashCard>
        </v-col>
        <v-col>
            <DashCard body-class="text-right">
                <v-btn depressed color="primary" class="subtitle-2" large @click="upload">
                    Guardar cambios
                </v-btn>
                <v-btn depressed color="primary" class="subtitle-2 ml-2" large :to="{ name: 'dashboard_cap_item', params: { id: item.curso } }" exact>
                    Descartar
                </v-btn>
            </DashCard>
        </v-col>
        <Message ref="success" @onClose="$router.push({ name: 'dashboard_cap_item', params: { id: item.curso } })" />
        <Message ref="error" />
        <DialogPreviewPresentacion ref="pdf_preview" />
        <DialogSelectPresentacion ref="pdf_selector" @save="set_presentacion" />
    </v-row>
</template>

<script>
import Message from '@/shared/components/Message'
import DashCard from '@/shared/components/DashCard'
import DatePickerText from '@/shared/components/DatePickerText'
import PreguntasEditor from '@/educap/components/PreguntasEditor'
import DialogSelectPresentacion from '@/educap/dialogs/DialogSelectPresentacion'
import DialogPreviewPresentacion from '@/educap/dialogs/DialogPreviewPresentacion'
import { axiosHelper, messages, valid_required } from '@/helpers'

export default {
    components: {
        DashCard,
        Message,
        DatePickerText,
        PreguntasEditor,
        DialogSelectPresentacion,
        DialogPreviewPresentacion
    },
    data: () => ({
        rules: {
            required: valid_required
        },
        item: {
            preguntas: []
        }
    }),
    created() {
        const edit = this.$route.meta.edit_mode;

        if(!edit) {
            this.item.curso = parseInt(this.$route.params.id);
        }
    },
    mounted() {
        let title = "Crear/editar evaluación";
        const edit = this.$route.meta.edit_mode;
        
        if(!edit) {
            title = "Crear evaluación";
        } else {
            axiosHelper.get('edu/evaluaciones/' + this.$route.params.id + '/editor/')
                    .then(response => {
                        this.item = response.data.item;
                        this.item.preguntas = this.item.preguntas.sort((x, y) => x.qid - y.qid);
                        this.title = this.index.nombre;
                    });
            title = "Editar evaluación";
        }

        this.$parent.$parent.set_title(title, "edit");
    },
    methods: {
        set_presentacion(file) {
            this.item.presentacion = file;
            this.$forceUpdate();
        },
        upload() {
            const edit = this.$route.meta.edit_mode;

            if(!this.$refs.form.validate()) {
                this.$refs.error.show(messages.titles['error_generico'], messages.texts['error_check_fields'], "error");
                return;
            }

            if(!edit && !this.item.presentacion) {
                this.$refs.error.show(messages.titles['error_generico'], "La evaluación requiere de al menos un material de apoyo", "error");
                return;
            }

            if(!edit) {
                this.item.curso = parseInt(this.$route.params.id);  
            }
            
            var final_item = { ...this.item };
            if(!!final_item.presentacion) {
                final_item.presentacion = final_item.presentacion.id;
            } else {
                this.$refs.error.show(messages.titles['error_generico'], "La evaluación requiere de al menos un material de apoyo", "error");
                return;
            }
            
            if(!edit) {      
                axiosHelper.post('edu/evaluaciones/', final_item)
                        .then(this.success)
                        .catch(this.error);
            } else {
                axiosHelper.put('edu/evaluaciones/' + this.$route.params.id + '/', final_item)
                        .then(this.success)
                        .catch(this.error);
            }
        },
        success(response) {
            const edit = this.$route.meta.edit_mode;
            this.$refs.success.show(messages.titles['success_generico'], messages.texts[edit ? 'success_upload_edit' : 'success_upload_new'], "success");
        },
        error(response) {
            this.$refs.error.show(messages.titles['error_generico'], messages.texts['error_desconocido'], "error");
        }
    }
}
</script>