<template>
    <CustomDialog ref="dialog" title="Seleccionar presentación" @accept="select" @cancel="cancel">
        <v-row no-gutters>
            <v-col cols="12">
                <v-row no-gutters class="ma-0">
                    <v-col class="flex-grow-0 flex-shrink-1 pa-1 my-auto">
                        <v-btn depressed large class="subtitle-2" color="primary" @click="go_upload">
                            <v-icon left>cloud_upload</v-icon>Subir archivo (PDF)
                        </v-btn>
                    </v-col>
                    <v-col class="flex-grow-1 flex-shrink-0 pa-1">
                        <v-label>Buscar por nombre</v-label>
                        <v-text-field hide-details solo outlined flat dense v-model="busqueda" placeholder="Buscar..." prepend-inner-icon="search" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="py-2">
                <v-card outlined flat tile height="400" class="overflow-y-auto">
                    <EmptyCard style="height: 100%" v-if="loading || actual_list.length < 1" icon="inbox" :text="loading ? 'Cargando...' : 'Sin presentaciones'" />
                    <v-item-group v-model="selected">
                        <v-row no-gutters>
                            <v-item :value="item"  :key="i" v-for="(item, i) in actual_list" v-slot:default="{ active, toggle }">
                                <v-col cols="12" sm="4">
                                    <v-card class="ma-1 text-center" outlined flat :key="i" @click="toggle" :color="active ? 'primary' : null" :dark="active">
                                        <v-card-text>
                                            <v-icon size="64" class="d-block">picture_as_pdf</v-icon>
                                            <span class="d-block font-weight-bold">{{ item.nombre }}</span>
                                            <span class="d-block">{{ item.creacion | moment("LL HH:mm") }}</span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-item>
                        </v-row>
                    </v-item-group>
                </v-card>
            </v-col>
            <v-col cols="12" v-if="!!selected">
                <v-row no-gutters>
                    <v-col class="flex-grow-1 flex-shrink-0">
                        <v-label>Seleccionado: </v-label>
                        <span class="subtitle-1 d-block text--primary">{{ selected.nombre }}</span>
                        <span class="subtitle-2 d-block text--primary">{{ selected.creacion | moment("LL HH:mm") }}</span>
                    </v-col>
                    <v-col class="flex-grow-0 flex-shrink-1 my-auto">
                        <!-- Descomentar cuando este disponible el visualizador de pdf -->
                        <!-- <v-btn depressed large dark color="primary" class="subtitle-2" @click="show_preview(selected.archivo)">
                            <v-icon left>visibility</v-icon>
                            Vista previa
                        </v-btn> -->
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <DialogPreviewPresentacion ref="preview" />
        <DialogUploadPresentacion ref="upload" @success="load_list" />
    </CustomDialog>
</template>

<script>
import EmptyCard from '@/shared/components/EmptyCard'
import CustomDialog from '@/shared/components/CustomDialog'
import DialogUploadPresentacion from './DialogUploadPresentacion'
import DialogPreviewPresentacion from './DialogPreviewPresentacion'
import { axiosHelper, messages } from '@/helpers'

export default {
    components: {
        CustomDialog,
        EmptyCard,
        DialogUploadPresentacion,
        DialogPreviewPresentacion
    },
    computed: {
        actual_list() {
            if(!this.busqueda) return this.list;
            return this.list.filter(x => x.nombre.toUpperCase().search(this.busqueda.toUpperCase()) > -1);
        }
    },
    data: () => ({
        list: [],
        loading: false,
        selected: null,
        busqueda: null
    }),
    methods: {
        show(item) {
            this.$refs.dialog.show();
            this.load_list();
        },
        show_preview(archivo) {
            this.$refs.preview.show(archivo);
        },
        load_list() {
            this.loading = true;
            this.list = [];
            axiosHelper.get('edu/presentaciones/')
                    .then(response => {
                        this.list = response.data;
                        this.loading = false;
                    })
                    .catch(response => {
                        this.loading = false;
                    });
        },
        go_upload() {
            this.$refs.upload.show();
        },
        select() {
            if(!this.selected) {
                this.$refs.dialog.$refs.message.show(messages.titles["error_generico"], "Seleccione un archivo", "error");
                return;
            }

            this.$refs.dialog.hide();
            this.$emit('save', this.selected);
        },
        cancel() {
            this.$refs.dialog.hide();
            this.selected = null;
        }
    }
}
</script>