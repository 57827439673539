<template>
    <v-dialog v-model="visible" max-width="800px" transition="fade-transition">
        <WindowCard :title="title" title-icon="style" :actions="true" actions-align="right">
            <v-form id="form" ref="form">
                <v-row>
                    <v-col cols="12" sm="8">
                        <v-label>Encabezado</v-label>
                        <v-textarea v-model="item.texto" auto-grow hide-details solo outlined flat placeholder="Pregunta" counter="1000" />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-label>Nº de alternativas visibles</v-label>
                        <v-text-field v-model.number="item.cantidad" type="number" min="2" max="6" solo outlined dense flat />
                    </v-col>
                    <v-col cols="12">
                        <v-label>Alternativas</v-label>
                        <template v-for="(alt, i) in item.alternativas">
                            <v-row :key="i" class="ma-0" no-gutters>
                                <v-col class="flex-grow-1 flex-shrink-0">
                                    <v-text-field 
                                        class="mb-2" dense solo outlined flat hide-details 
                                        v-model="alt.texto">
                                        <template v-slot:prepend-inner>
                                            <v-btn icon @click="alt.es_correcto = !alt.es_correcto" title="Marcar como alternativa correcta">
                                                <v-icon v-if="alt.es_correcto">check_box</v-icon>
                                                <v-icon v-else>check_box_outline_blank</v-icon>
                                            </v-btn>
                                        </template>
                                        <template v-slot:append>
                                            <v-btn icon @click="remove(i)" title="Eliminar alternativa">
                                                <v-icon>close</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </template>
                        <v-btn color="secondary" depressed block class="subtitle-2" @click="item.alternativas.push({ texto: '', es_correcto: false })">
                            <v-icon left>add</v-icon>
                            Añadir alternativa
                        </v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-card outlined>
                            <v-list dense class="ma-0 pa-0">
                                <v-list-group prepend-icon="help_outline">
                                    <template v-slot:activator>
                                        <v-list-item-title>
                                        Acerca de las alternativas
                                        </v-list-item-title>
                                    </template>
                                    <v-list-item>
                                        <v-card-text>
                                        <p>Es posible añadir <b>más alternativas que la cantidad visible</b>, como también <b>más de una alternativa correcta.</b></p>
                                        <p>Durante la evaluación se seleccionará solo una porción de estas alternativas, donde solo una respuesta será la correcta. 
                                            Esta porción cambiará aleatoriamente cada vez que se rinda la evaluación o cuando se responda incorrectamente.</p>
                                        </v-card-text>
                                    </v-list-item>
                                </v-list-group>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </v-form>
            <template slot="actions">
                <v-btn depressed color="primary" class="subtitle-2" large @click="save">
                    <v-icon left>done</v-icon>
                    Aceptar
                </v-btn>
                <v-btn depressed color="primary" class="subtitle-2" large @click="cancel">
                    <v-icon left>close</v-icon>
                    Cancelar
                </v-btn>
            </template>
        </WindowCard>
        <Message ref="error" />
    </v-dialog>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'
import Message from '@/shared/components/Message'
import { messages } from '@/helpers'

export default {
    components: {
        WindowCard,
        Message
    },
    data: () => ({
        visible: false,
        edit_mode: false,
        edit_index: -1,
        item: {},
        default_item: {
            cantidad: 3,
            texto: "",
            alternativas: [],
            ayuda: null
        }
    }),
    computed: {
        title() {
            return this.edit_mode ? "Editar pregunta" : "Crear nueva pregunta";
        }
    },
    methods: {
        show(target, index) {
            this.visible = true;
            this.edit_mode = !!target;

            if(!this.edit_mode) {
                this.default_item.ayuda = null;
                this.default_item.cantidad = 3;
                this.default_item.alternativas = [];
                this.item = Object.assign({}, this.default_item);
                this.edit_index = null;
            } else {
                this.item = Object.assign({}, target);
                this.edit_index = index;
            }
        },
        save() {
            if(!this.$refs.form.validate()) {
                this.$refs.error.show(messages.titles["error_generico"], messages.texts["error_check_fields"], "error");
                return;
            }

            if (this.item.alternativas.length < this.item.cantidad) {
                this.$refs.error.show(messages.titles["error_generico"], `La pregunta necesita de al menos ${this.item.cantidad} alternativas`, "error");
                return;
            }

            const correctas = this.item.alternativas.filter(x => !!x.es_correcto).length;

            if (correctas < 1) {
                this.$refs.error.show(messages.titles["error_generico"], 'La pregunta necesita de al menos 1 alternativa correcta', "error");
                return;
            }

            let new_item = Object.assign({}, this.item);

            if(!this.edit_mode) {
                this.$emit("save-new", new_item);
            } else {
                this.$emit("save-edit", new_item, this.edit_index);
            }
            this.visible = false;
        },
        cancel() {
            this.visible = false;
        },
        remove(item) {
            var index = this.item.alternativas.indexOf(item);
            this.item.alternativas.splice(this.item.alternativas.indexOf(item), 1);
        }
    }
}
</script>