<template>
    <div>
        <v-flex class="text-right">
            <v-btn-toggle v-model="preguntas_list_view" mandatory class="ma-2">
                <v-btn :value="false" icon title="Vista de cuadricula">
                    <v-icon>view_module</v-icon>
                </v-btn>
                <v-btn :value="true" icon title="Vista de lista">
                    <v-icon>view_list</v-icon>
                </v-btn>
            </v-btn-toggle>
        </v-flex>
        <v-divider />
        <v-data-table :items="value" :headers="table_header" v-if="preguntas_list_view" :mobile-breakpoint="0">
            <template v-slot:item.texto="{ item }">
                <span v-html="item.texto.replace(/\n/gi, '<br/>')"></span>
            </template>
            <template v-slot:item.alternativas="{ item }">
                <ul>
                    <li>{{ item.alternativas.length }} alternativas.</li>
                    <li>{{ item.alternativas.filter(x => !!x.es_correcto).length }} son correctas.</li>
                    <li>Se mostrarán {{ item.cantidad }} por cada intento.</li>
                </ul>
            </template>
            <template v-slot:item.ayuda="{ item }">
                <v-chip dark color="green" v-if="!!item.ayuda">Posee ayuda</v-chip>
                <v-chip dark color="red" v-else>No posee ayuda</v-chip>
            </template>
            <template v-slot:item.actions>
                <v-menu>
                    <template v-slot:activator="{ on }">
                        <v-btn fab small text color="primary" depressed v-on="on">
                            <v-icon>more_vert</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item @click="open_editor">
                            <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                            <v-list-item-title>Editar</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="delete_item">
                            <v-list-item-icon><v-icon>close</v-icon></v-list-item-icon>
                            <v-list-item-title>Eliminar</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <template v-else>
            <v-data-iterator :items="value" hide-default-footer :items-per-page.sync="pagination.item_per" :page="pagination.page">
                <template v-slot:no-data>
                    <v-card class="ma-4" outlined flat>
                    <v-card-text class="my-4 py-2" align="center">
                        <v-icon size="64">error_outline</v-icon>
                        <span class="my-4 d-block">Por favor, inserte una nueva pregunta</span>
                    </v-card-text>
                    </v-card>
                </template>
                <template v-slot:item="{ item }">
                    <PreguntasEditorItem 
                        :num="value.indexOf(item) + 1"
                        :data="item" class="ma-2" 
                        @edit-item="open_editor"
                        @edit-help="open_ayuda_editor"
                        @delete-item="delete_item"
                    />
                </template>
                <template v-slot:footer>
                    <v-pagination v-model="pagination.page" :length="value.length" />
                </template>
            </v-data-iterator>
            <v-divider />
        </template>
        <DialogNewPregunta ref="dialog" @save-new="add_to_list" @save-edit="edit_to_list" />
        <DialogEditAyuda ref="help_dialog" @save="add_to_item" />
        <CommonDialog ref="ask_delete" @onClose="delete_from_list" />
    </div>
</template>

<script>
import PreguntasEditorItem from '@/educap/components/PreguntasEditorItem'
import DialogNewPregunta from '@/educap/dialogs/DialogNewPregunta'
import DialogEditAyuda from '@/educap/dialogs/DialogEditAyuda'
import CommonDialog from '@/shared/components/CommonDialog'

export default {
    components: {
        CommonDialog,
        DialogNewPregunta,
        DialogEditAyuda,
        PreguntasEditorItem
    },
    props: ['value'],
    data: () => ({
        pagination: {
            page: 1,
            item_per: 1,
            total: 1
        },
        preguntas_list_view: false,
        table_header: [
            { text: "Pregunta", value: "texto", sortable: false },
            { text: "Nº alternativas", value: "alternativas", sortable: false },
            { text: "Ayuda", value: "ayuda", sortable: false, width: "10%" },
            { text: "", value: "actions", sortable: false, width: "10%" }
        ]
    }),
    methods: {
        open_editor(item) {
            this.$refs.dialog.show(item, this.value.indexOf(item));
        },
        open_ayuda_editor(item) {
            this.$refs.help_dialog.show(item, this.value.indexOf(item));
        },
        trigger_new() {
            this.$refs.dialog.show();
        },
        add_to_item(ayuda, index) {
            this.value[index].ayuda = ayuda;
        },
        add_to_list(item) {
            item.qid = this.value.length + 1;
            this.value.push(item);
            this.$forceUpdate();
        },
        edit_to_list(item, index) {
            this.value.splice(index, 1, item);
            this.$forceUpdate();
        },
        delete_from_list(item) {
            var index = this.value.indexOf(item);
            this.value.splice(index, 1);
        },
        delete_item(item) {
            this.$refs.ask_delete.showDialog("Eliminar datos", "¿Está seguro/a que desea eliminar los datos?", "yes_no", item);
        }
    }
}
</script>