<template>
    <v-dialog v-model="visible" max-width="800px" transition="fade-transition">
        <WindowCard title="Editor de Ayudas" title-icon="style" :actions="true" actions-align="right">
            <v-row>
                <v-col cols="12">
                    <v-label>Detalles de la pregunta</v-label>
                    <v-card outlined flat>
                        <v-card-text>
                            <span class="d-block text--primary subtitle-1 pb-4">{{ pregunta?.texto }}</span>
                            <ol type="a" class="text--primary">
                                <li v-for="(alt, i) in alts" :key="i" class="subtitle-1">
                                    <template v-if="alt.es_correcto"><b>{{ alt?.texto }}</b></template>
                                    <template v-else>{{ alt?.texto }}</template>
                                </li>
                            </ol>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-form id="form" ref="form">
                        <v-label>Instrucción</v-label>
                        {{ pregunta.ayuda }}
                        <v-textarea v-model="item" solo outlined flat placeholder="Texto que contenga información que pueda servir de guía para responder a la pregunta" counter="250" :rules="[rules.required]"/>
                    </v-form>
                </v-col>
            </v-row>
            <template slot="actions">
                <v-btn depressed color="primary" class="subtitle-2" large @click="save">
                    <v-icon left>done</v-icon>
                    Aceptar
                </v-btn>
                <v-btn depressed color="primary" class="subtitle-2" large @click="cancel">
                    <v-icon left>close</v-icon>
                    Cancelar
                </v-btn>
            </template>
        </WindowCard>
        <Message ref="error" />
    </v-dialog>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'
import Message from '@/shared/components/Message'
import { messages } from '@/helpers'
import { valid_required } from '@/helpers/validate'

export default {
    components: {
        WindowCard,
        Message
    },
    data: () => ({
        visible: false,
        item: null,
        pregunta: null,
        rules: {
            required: valid_required
        }
    }),
    computed: {
        alts() {
            let correcta = this.pregunta?.alternativas.filter(x => x.es_correcto).sort(() => 0.5 - Math.random())[0];
            let resto = this.pregunta?.alternativas.filter(x => !x.es_correcto).sort(() => 0.5 - Math.random()).slice(0, this.pregunta.cantidad - 1);

            let full = [correcta, ...resto];
            return full.sort(() => 0.5 - Math.random());
        }
    },
    methods: {
        show(target, index) {
            this.visible = true;
            this.pregunta = Object.assign({}, target);
            this.index = index;
            this.item = this.pregunta.ayuda;
        },
        save() {
            if(!this.$refs.form.validate()) {
                this.$refs.error.show(messages.titles["error_generico"], messages.texts["error_check_fields"], "error");
                return;
            }

            this.$emit('save', this.item, this.index);
            this.visible = false;
        },
        cancel() {
            this.visible = false;
        }
    }
}
</script>