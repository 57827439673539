<template>
    <v-card outlined>
        <v-card-title class="pa-0">
            <v-row class="ma-0 pa-0">
                <v-col class="flex-grow-1 flex-shrink-0">
                    <p class="ma-0 pa-0 font-weight-regular body-1">
                    {{ data.qid || 0 }}) <span :inner-html.prop="data.texto | parse_html"></span>
                    </p>
                </v-col>
                <v-col class="flex-grow-0 flex-shrink-1">
                <v-menu>
                    <template v-slot:activator="{ on }">
                        <v-btn dark small icon fab v-on="on">
                            <v-icon>more_vert</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item @click="$emit('edit-item', data)">
                            <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                            <v-list-item-title>Editar</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="$emit('delete-item', data)">
                            <v-list-item-icon><v-icon>close</v-icon></v-list-item-icon>
                            <v-list-item-title>Eliminar</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-flex grow>
                <v-row>
                    <v-col cols="12" md="6">
                        <ol type="a" class="text--primary">
                            <li v-for="(alt, i) in alts" :key="i" class="subtitle-1">
                                <template v-if="alt.es_correcto"><b>{{ alt.texto }}</b></template>
                                <template v-else>{{ alt.texto }}</template>
                            </li>
                        </ol>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-flex class="ma-0 pa-0 text-right mr-n2">
                            <v-btn depressed dark color="indigo" class="subtitle-2 ma-1" @click="$emit('edit-help', data)">
                                <v-icon left>edit</v-icon>
                                {{ !!data.ayuda ? 'Editar ayuda' : 'Añadir ayuda' }}
                            </v-btn>
                            <v-btn depressed dark color="red" v-if="!!data.ayuda" class="subtitle-2 ma-1" @click="delete_ayuda">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-flex>
                        <v-card outlined flat v-if="!!data.ayuda">
                            <v-list dense class="ma-0 pa-0">
                                <v-list-group>
                                    <template v-slot:activator>
                                        <v-list-item-title>
                                        Revisar ayuda
                                        </v-list-item-title>
                                    </template>
                                        <v-divider />
                                    <v-list-item class="pt-2">
                                        <p>{{ data.ayuda }}</p>
                                    </v-list-item>
                                </v-list-group>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </v-flex>
        </v-card-text>
    </v-card>
</template>

<style scoped>
.v-card__title {
    background: linear-gradient(0deg, var(--v-primary-lighten3) 0%, var(--v-primary-base) 100%) !important;
    border-bottom: 1px solid var(--v-primary-lighten1);
    color: white;
    text-shadow: 0px 1px 0px rgba(0,0,0,0.55), 0px 3px 10px rgba(0,0,0,0.3);
}
</style>

<script>
export default {
    props: ['data', 'num'],
    data: () => ({
        alts: []
    }),
    created() {
        this.generate_random();
    },
    methods: {
        delete_ayuda() {
            this.data.ayuda = null;
            this.$forceUpdate();
        },
        generate_random() {
            let correcta = this.data.alternativas.filter(x => x.es_correcto).sort(() => 0.5 - Math.random())[0];
            let resto = this.data.alternativas.filter(x => !x.es_correcto).sort(() => 0.5 - Math.random()).slice(0, this.data.cantidad - 1);

            let full = [correcta, ...resto];
            this.alts = full.sort(() => 0.5 - Math.random());
        }
    },
    watch: {
        data: function(_new, _old) {
            this.generate_random();
        }
    }
}
</script>